import React from 'react';
import { MessageDiv, StyledArticle, StyledFileContainer } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2, StyledTable } from '../../styles/common';
import { SEO } from '../../components/SEO';

export function Head() {
	return (
		<SEO
			title="Wynajem taboru autobusowego i tramwajowego"
			description="Świadczenie usług transportowych."
		/>
	);
}

function PrzewozyPage() {
	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Wynajem taboru autobusowego i tramwajowego
				</StyledH2>
				<MessageDiv>
					<p>
						Świadczenie usług pojazdami specjalistycznymi na terenie miasta
						Bydgoszczy oraz dowóz autobusów na naprawy gwarancyjne.
					</p>
					<p>
						Kontakt: Wydział Infrastruktury Torowo-Sieciowej ul. Toruńska 278
						tel.: <a href="tel:523249475">(52) 324-94-75</a>
					</p>
					<h3>Ceny umowne na wynajem pozostałych pojazdów</h3>
					<StyledTable>
						<tr>
							<th>L.p.</th>
							<th>Wyszczególnienie</th>
							<th>Cena netto</th>
						</tr>
						<tr>
							<td rowSpan={4} style={{ textAlign: 'center' }}>
								1.
							</td>
							<td colSpan={2}>
								POJAZD SIECIOWY (samochodowy, dwudrożny 1000 mm)
							</td>
						</tr>
						<tr>
							<td>cena za 1 godzinę z obsługą jednoosobową</td>
							<td>350,00 zł</td>
						</tr>
						<tr>
							<td>cena za 1 godzinę z obsługą trzyosobową</td>
							<td>600,00 zł</td>
						</tr>
						<tr>
							<td>
								cena za 1 km za dojazd z zajezdni tramwajowej na miejsce pracy
							</td>
							<td>15,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								2.
							</td>
							<td colSpan={2}>TRAMWAJ TECHNICZNY SIECIOWY</td>
						</tr>
						<tr>
							<td>z obsługą dwuosobową bez przyczepy lub z przyczepą</td>
							<td>wg indywidualnej wyceny</td>
						</tr>
					</StyledTable>
					<p>
						Do ustalonej ceny należy doliczyć podatek „VAT” wg obowiązujących
						stawek.
					</p>
					<h3>Ceny umowne na dowóz autobusów na naprawy gwarancyjne</h3>
					<StyledTable>
						<tr>
							<th>L.p.</th>
							<th>Wyszczególnienie</th>
							<th>Cena netto</th>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								1.
							</td>
							<td colSpan={2}>AUTOBUSY KRÓTKIE</td>
						</tr>
						<tr>
							<td>cena 1 wzkm</td>
							<td>7,00 zł</td>
						</tr>
						<tr>
							<td rowSpan={2} style={{ textAlign: 'center' }}>
								2.
							</td>
							<td colSpan={2}>AUTOBUSY DŁUGIE</td>
						</tr>
						<tr>
							<td>cena 1 wzkm</td>
							<td>9,00 zł</td>
						</tr>
					</StyledTable>
					<p>
						Do ustalonej ceny należy doliczyć podatek „VAT” wg obowiązujących
						stawek.
					</p>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default PrzewozyPage;
